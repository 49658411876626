import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import PageTransition from 'gatsby-plugin-page-transitions';

import Footer from '../components/common/footer';
import { AppearText, AppearImage } from '../components/common/appear';
import P from '../components/common/p';
import TagButton from '../components/tagButton';

const Page = styled.div`
  min-height: 100vh;
  background: #FFF;
  padding-top: 241px;
  max-width: 1241px;
  margin: 0 auto;
  padding: 0 135px;
  padding-top: 241px;

  width: 100%;
  max-width: 1620px;
  @media (max-width: 1600px) {
    max-width: 1241px;
    padding: 0 104px;
    padding-top: 241px;
  }
  @media (max-width: 1200px) {
    padding: 0 57px;
    padding-top: 241px;
    min-height: auto;
  }

  @media (max-width: 600px) {
      padding: 0 15px;
      padding-top: 180px;
  }
`;

const FooterWrapper = styled.div`
    margin-top: 140px;
    padding-bottom: 60px;
    width: 100%;
    @media (max-width: 600px) {
        margin-top: 60px;
    }
`;

const H1 = styled.h1`
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 92px;
    line-height: 80px;
    color: #0064BA;
    /* or 87% */
    @media (max-width: 1200px) {
        font-style: normal;
        font-weight: bold;
        font-size: 92px;
        line-height: 80px;
    }

    @media (max-width: 600px) {
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 54px;
    }
`;

const H3 = styled.h3`
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #0064BA;
    margin-top: 61px;
    margin-bottom: 40px;
    @media (max-width: 600px) {
        font-size: 28px;
        line-height: 38px;
        margin-top: 41px;
        margin-bottom: 22px;
    }
`;

const PdfAboutDownload = styled.div`
    margin-top: 93px;
    margin-bottom: 148px;
    width: 100%;
    max-width: 750px;
    @media (max-width: 600px) {
        display: none;
    }
`;


const PdfAboutDownloadMobile = styled.div`
    margin-top: 53px;
    margin-bottom: 68px;
    width: 100%;
    max-width: 750px;
    display: none;
    @media (max-width: 600px) {
        display: block;
    }
`;

const RowPartners = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const ImgPartners = styled.img`
    max-width: 320px;
    height: auto;
`;
const ImgPartnersParent = styled.div`
    margin-bottom: 30px;
    @media (max-width: 600px) {
        width: 100%;
        text-align: center;
    }
`;
const exampleText = `
    Blue Ocean is your partner for your best solutions.<br/>
    <br />
    Diving into insurance field is always a big issue; that is why our clients and stakeholders need a professional help. We take care of their needs and belongings, giving them the best action plan possible. The solutions we can find, and our marketplace are the most effective and secure. We deal with any kind of need, from personal insurances to commercial insurances: Blue Ocean is the best choice for your certainty.<br /><br />
    Our focus is to develop actions that are supposed to give everyone a unique service in a growing field such as insurances is. Our values and passion make the change possible: we launched innovative products and services, basing our work only on our client’s needs. Through innovation and technology, we apply effectively our diverse skills on every single aspect of insurance service. Our experience will guide our clients and partners into a new concept of insurance, a wide world of possibility.<br />
    <br />We think about not only their actual needs, but even their future passions or projects.
`;

// need to adapt to backend
const AboutPage = ({ data }) => (
    <PageTransition>
        <>
            <Helmet
                script={[{
                    type: 'text/javascript',
                    innerHTML: `(function(o){var b="https://briskeagle.io/anywhere/",t="dffd427699944f1980ba26862e4c241047b0deeab5144e6285e53f602bfeb61b",a=window.AutopilotAnywhere={_runQueue:[],run:function(){this._runQueue.push(arguments);}},c=encodeURIComponent,s="SCRIPT",d=document,l=d.getElementsByTagName(s)[0],p="t="+c(d.title||"")+"&u="+c(d.location.href||"")+"&r="+c(d.referrer||""),j="text/javascript",z,y;if(!window.Autopilot) window.Autopilot=a;if(o.app) p="devmode=true&"+p;z=function(src,asy){var e=d.createElement(s);e.src=src;e.type=j;e.async=asy;l.parentNode.insertBefore(e,l);};y=function(){z(b+t+'?'+p,true);};if(window.attachEvent){window.attachEvent("onload",y);}else{window.addEventListener("load",y,false);}})({});`
                }]}
            />
            <Page>
                <AppearText><H1>{data.markdownRemark && data.markdownRemark.frontmatter.title}</H1></AppearText>
                <AppearText><P dangerouslySetInnerHTML={{
                    __html: data.markdownRemark && data.markdownRemark.frontmatter.paragraph.replace(/\\/g, '<br/>')
                }} /></AppearText>
                <H3><AppearText>{data.markdownRemark && data.markdownRemark.frontmatter.second_title}</AppearText></H3>
                <P nmt>
                    <AppearText>
                        {data.markdownRemark && data.markdownRemark.frontmatter.second_paragraph.replace(/\\/g, '<br/>')}
                    </AppearText>
                </P>
                
                <H3><AppearText>Our Vision</AppearText></H3>
                <P nmt><AppearText>
                    Our vision has everything in common with resilience and endeavour.<br />
                    
                    We expect to increase our leading position in insurance broker field more and more, mainly by introducing smarter and more innovative processes, following the increasing curve of technology and digitalization. Growing from our knowledge and experience, we want to base our vision on our professional network, to be the leading insurance broker force even for foreign partners.<br /><br />Our strength is the personalized customer experience, understanding his need and requirement, find he best partner and solution for them, whether it’s companies, groups or individuals. Harmony is everything, for us, and it has a special place in our vision of business: commercial and corporate will both have a personal, tailored place in our portfolio. Integrality, expertise and trust are our leading values, the pillars that will guide us toward our vision and future.
                </AppearText></P>
                <AppearImage>
                    <PdfAboutDownload>
                        <TagButton 
                            image={3}
                            boldText={data.markdownRemark && data.markdownRemark.frontmatter.text_pdf}
                            normalText=""
                            full
                            onClickHandler={() => {
                                const url = data.markdownRemark && data.markdownRemark.frontmatter.pdf_download;
                                if ( url ) {
                                    let win = window.open(url, '_blank');
                                    win.focus();
                                }
                            }}
                        />
                    </PdfAboutDownload>
                    <PdfAboutDownloadMobile>
                        <TagButton
                            image={3}
                            boldText={data.markdownRemark && data.markdownRemark.frontmatter.text_pdf.split(' ')[0]}
                            normalText={data.markdownRemark && data.markdownRemark.frontmatter.text_pdf.split(' ').splice(1).join(' ')}
                            full
                            onClickHandler={() => {
                                const url = data.markdownRemark && data.markdownRemark.frontmatter.pdf_download;
                                if (url) {
                                    let win = window.open(url, '_blank');
                                    win.focus();
                                }
                            }}
                        />
                    </PdfAboutDownloadMobile>
                </AppearImage>
                <H3><AppearText>Our Partners</AppearText></H3>
                <RowPartners>
                    {
                        data.markdownRemark && data.markdownRemark.frontmatter.partners.map((element, index) => {
                            return <ImgPartnersParent>
                                <AppearImage>
                                    <ImgPartners
                                        src={element.logo}
                                        alt={element.alttext}
                                        first={index === 0}
                                        last={index !== 0 && index % 4 === 0}
                                    />
                                </AppearImage>
                            </ImgPartnersParent>
                        })
                    }
                </RowPartners>
                <FooterWrapper >
                    <Footer noPadding />
                </FooterWrapper> 
            </Page>
        </>
    </PageTransition>
)

export default function myAbout(props) {
    return (
        <StaticQuery
            query={graphql`
                query {
                    markdownRemark(frontmatter: {templateKey: {eq: "about-page"}}) {
                        frontmatter {
                            title
                            paragraph
                            second_title
                            second_paragraph
                            third_title
                            third_paragraph
                            pdf_download
                            text_pdf
                            partners {
                                logo
                                alttext
                            }
                        }
                    }
                }
            `}
            render={data => <AboutPage data={data} {...props} />}
        />
    )
};
